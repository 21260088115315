import React from 'react'
import './App.css';
import Navbar from './Componets/Navbar';
import Home from './Componets/Home';
import { Route, Routes } from 'react-router-dom';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Service from './Pages/Service';


function App() {
  return (
    <>
      <div className='navbar bg-none p-0'>
        {/* <Navbar /> */}
      </div>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/services' element={<Service/>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>
    </>
  );
}

export default App;
