import React from 'react'
import Navbar from '../Componets/Navbar'
import Main_footer from '../Componets/Main_footer'
import { Container } from '@mui/material'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TitleWithStroke from '../Componets/TitleWithStroke';
import Steps from './Steps_2';
import Company from '../Componets/Company';

const About = () => {
    return (
        <>
            {/*  */}
            <div className='navbar bg-none p-0'>
                <Navbar />
            </div>
            {/*  */}


            {/*  */}
            <div>
                <div className=' about-bg py-[200px]'>
                    <div>
                        <Container>

                            <div className='pt-[200px]'>
                                <TitleWithStroke backText="Transx" title="About Us" align='left' color="white" />
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
            {/*  */}


            {/*  */}
            <div className=' py-[150px]'>
                <Container>
                    <div className='grid grid-cols-1 lg:grid-cols-2  gap-[50px]'>
                        <div className='about-feature  relative '>
                            <img src={require("../image/section_2.jpg")} alt="" className='w-full' />
                            <div className=' bg-[#2B5BAC] bg-img-about absolute left-0 bottom-0 lg:right-[45px] lg:top-[80%]'>
                                <h1 className='text-white font-bold text-[35px]'>Faster than <br /> you can imagine</h1>
                            </div>
                        </div>
                        <div className='Feature-content '>
                            <p className='uppercase tracking-[1.8px] leading-[19.2px] font-bold text-[12px] text-[#aaadb1] pb-[20px]'>about us</p>

                            <h1 className='text-[45px] text-[#414A53] font-bold tracking-[-1.35px] leading-[50px] pb-[20px]'>Powerful Features of <span className='text-[#4EAE98]'>Transporterium</span> company</h1>
                            <p className=' mb-[30px]'>
                                <strong className='text-[#414a53] leading-[27.2px]'>
                                    Brook trout powen harelip sucker gibberfish beluga sturgeon coelacanth tidewater goby elephant fish yellowtail slender snipe eel rasbora.
                                </strong>
                            </p>

                            <p className=' leading-[27.2px] text-[#696e76] mb-[30px]'>Rainbowfish powen paddlefish brotula Arctic char zebra bullhead shark. Yellowhead jawfish gianttail temperate ocean-bass Atlantic eel river stingray skilfish. Flounder.</p>

                            <p className=' leading-[27.2px] text-[#696e76] mb-[40px]'>Skilfish, halosaur skilfish manefish, bonnetmouth alfonsino largenose fish moonfish aruana glowlight danio. Basking shark halibut, North Pacific.
                            </p>
                            <div className=' space-y-6'>
                                <div className=' flex gap-5'>
                                    <img src={require('../image/check.png')} className=' h-[25px]' alt="" />
                                    <b className=' text-[#414a53]'>Red salmon skilfish, threadtail mullet southern flounder, orangespine unicorn fish flounder bobtail snipe eel.</b>
                                </div>
                                <div className=' flex gap-5'>
                                    <img src={require('../image/check.png')} className='h-[25px]' alt="" />
                                    <b className=' text-[#414a53]'>Trumpeter ropefish bonito roughy cobbler dogteeth tetra Russian sturgeon pollock sea snail.</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/*  */}


            {/*  */}
            <div className='py-[100px] bg-[#F3F6F5] '>
                <Container>
                    <div className=''>
                        <div className=' grid grid-cols-1 lg:grid-cols-2  gap-10 '>
                            <div className='Feature-content'>
                                <div>
                                    <p className='uppercase tracking-[1.8px] leading-[19.2px] font-bold text-[12px] text-[#aaadb1] pb-[20px]'>Mission</p>

                                    <h1 className='font-bold text-[45px] text-[#414a53]  tracking-[-1.35px] leading-[50px] pb-[30px]'>Our mission is to<span className='text-[#1eae98]'> Give you</span> Good Services</h1>
                                    <p className='pb-[30px]'>
                                        <strong className='text-[#414a53] leading-[27.2px]'>
                                            Trumpeter ropefish bonito roughy cobbler dogteeth tetra sturgeon pollock sea snail, saury woody sculpin southern sandfish blue. Tench South American darter bobtail snipe eel armored searobin lumpsucker.
                                        </strong>
                                    </p>

                                    <p className=' leading-[27.2px] text-[#696e76] mb-[30px]'>Skilfish, halosaur skilfish manefish, bonnetmouth alfonsino largenose fish moonfish aruana glowlight danio. Basking shark halibut, North Pacific daggertooth, bonnetmouth sand stargazer sand goby. Queen triggerfish sand dab hammerhead shark zebra trout pelican gulper, common tunny boarfish. Pleco riffle dace flier trunkfish North Pacific.</p>
                                </div>

                                <div className=' gap-8'>
                                    <div className='py-6 '>
                                        <strong className=' leading-[27.2px]
                                                 '>Andy Collins, Agrarium LLC</strong>
                                    </div>
                                </div>
                            </div>
                            <div className=' col-start-1 col-end-[-1] row-start-1 lg:col-start-2 lg:col-end-3 '>
                                <img src={require('../image/mission.jpg')} alt="" className=' w-full ' />
                            </div>
                        </div>
                    </div >
                </Container >
            </div>
            {/*  */}


            {/*  */}
            <div className=' py-[80px]'>
                <Container>
                    <div className=' achievement-bg '>
                        <div className='text-center'>
                            <p className=' uppercase tracking-[1.8px] leading-[19.2px] font-bold text-[14px] text-[#aaadb1] pb-[20px]'>Achivement</p>
                            <h1 className=' font-bold text-[45px] text-[#434A53] pb-[20px]'>High work achievements</h1>
                        </div>
                        <div className=' flex flex-wrap justify-evenly gap-10 lg:gap-[110px]'>
                            <div className=''>
                                <div className='gap-3 flex text-[#2B5BAC]'>
                                    <h1 className='font-bold text-[50px] lg:text-[100px]'>50</h1>
                                    <span className=' text-[20px] lg:text-[42px] tracking-[0.5px] leading-[1.1] font-bold content-end pb-[30px]'>cities</span>
                                </div>
                                <p className=' font-bold text-[#777F7F]'>Around the world</p>
                            </div>
                            <div>
                                <div className='gap-3 flex text-[#2B5BAC]'>
                                    <h1 className='font-bold text-[50px] lg:text-[100px]'>2</h1>
                                    <span className='text-[20px] lg:text-[42px] tracking-[0.5px] leading-[1.1] font-bold content-end pb-[30px]'>million</span>
                                </div>
                                <p className=' font-bold text-[#777F7F]'>Around the world</p>
                            </div>
                            <div>
                                <div className='gap-3 flex text-[#2B5BAC]'>
                                    <h1 className='font-bold text-[50px] lg:text-[100px]'>1.2</h1>
                                    <span className='text-[20px] lg:text-[42px] tracking-[0.5px] leading-[1.1] font-bold content-end pb-[30px]'>million</span>
                                </div>
                                <div>
                                    <p className='font-bold text-[#777F7F]'>Around the world</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/*  */}


            {/*  */}
            <div className=' py-[100px]'>
                <Container>
                    <div className='relative'>
                        <img src={require("../image/video_bg_new-1170x550.jpg")} alt="" />
                        <div className=' absolute top-[35%] left-[50%]'>
                            <div className='play-btn text-white items-center rounded-full py-3  px-3 w-[50px] h-[50px]'>
                                <PlayArrowIcon className='' />
                            </div>
                        </div>
                        <h1 className='absolute top-[50%] text-white left-[45%] font-bold text-[20px] leading-[34px]  '>Video Presentation</h1>
                    </div>
                </Container>
            </div>
            {/*  */}


            {/*  */}
            <div>
                <Steps />
            </div>
            {/*  */}


            {/*  */}
            <div>
                <div className='bg-[#32373F] py-[100px]'>
                    <div className='bg-help pb-[150px]'>
                        <Container>
                            <div>
                                <div className=' grid grid-cols-1  lg:grid-cols-2  w-[100%] gap-[57px]'>
                                    <div className=' text-white'>
                                        <p className=' font-bold uppercase text-[12px] pb-[15px] tracking-[1px]'>Creative Team</p>
                                        <h1 className=' font-bold text-[45px] leading-[50px] tarcking-[-1.35px] pb-[30px]'>The team that support and help you
                                        </h1>
                                        <p>Australian herring zingel arowana Pacific cod Red chub freshwater hatchetfish plaice; albacore starry flounder kingfish Owens pupfish butterflyfish noodlefish Atlantic eel</p>

                                        <div className=''>
                                            <button className='uppercase py-[14px] px-[40px] mt-[40px] leading-[24px] hover:text-[#222] rounded-br-2xl border-2 hover:bg-white hover:border font-semibold text-sm bg-transparent  transition-all '>More About</button>
                                        </div>
                                    </div>
                                    <div className=' grid grid-cols-1 md:grid-cols-2  gap-10'>
                                        <div className='relative overflow-hidden '>
                                            <div>
                                                <img src={require('../image/team_3-540x740.jpg')} alt="" className=' w-full' />
                                            </div>
                                            <div className='absolute transition-all duration-200 hover:overflow-visible hover:translate-y-[0px] bottom-0  transform translate-y-[60px] bg-[#2B5BAC] w-full team-content pl-[20px] pt-[30px] pb-[20px]'>
                                                <h1 className=' text-[12px] lg:text-[18px] font-bold text-white leading-[1.5]'>Claid Owen</h1>
                                                <p className=' text-[#6993cd] text-[12px] lg:text-[14px] font-normal mt-[3px]'>Company CEO</p>
                                                <div className=' flex mt-[15px] text-white gap-3 '>
                                                    <div>
                                                        <FacebookRoundedIcon fontSize='12px' />
                                                    </div>
                                                    <div>
                                                        <TwitterIcon fontSize='12px' />
                                                    </div>
                                                    <div>
                                                        <LinkedInIcon fontSize='12px' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='relative overflow-hidden '>
                                            <img src={require('../image/team_4-540x740.jpg')} alt="" />
                                            <div className='absolute transition-all duration-200 hover:overflow-visible hover:translate-y-[0px] top-[60%] transform translate-y-[45px] bg-[#2B5BAC] w-full team-content pl-[20px] py-[30px] pr-[16px]'>
                                                <h1 className=' text-[18px] font-bold text-white leading-[1.5]'>Ginger Trap</h1>
                                                <p className=' text-[#6993cd] text-[14px] font-normal mt-[3px]'>Service Analytic</p>
                                                <div className=' flex mt-[15px] text-white gap-3 '>
                                                    <div>
                                                        <FacebookRoundedIcon />
                                                    </div>
                                                    <div>
                                                        <TwitterIcon />
                                                    </div>
                                                    <div>
                                                        <LinkedInIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <div>
                    <Container>
                        <div className=' relative top-[-180px] flex gap-x-10'>
                            <div className='relative overflow-hidden '>
                                <img src={require('../image/team_1-540x740.jpg')} alt="" />
                                <div className='absolute transition-all duration-200 hover:overflow-visible hover:translate-y-[0px] top-[60%] transform translate-y-[45px] bg-[#2B5BAC]  w-full team-content pl-[20px] py-[30px] pr-[16px]'>
                                    <h1 className=' text-[18px] font-bold text-white leading-[1.5]'>Ginger Trap</h1>
                                    <p className=' text-[#6993cd] text-[14px] font-normal mt-[3px]'>Service Analytic</p>
                                    <div className=' flex mt-[15px] text-white gap-3 '>
                                        <div>
                                            <FacebookRoundedIcon />
                                        </div>
                                        <div>
                                            <TwitterIcon />
                                        </div>
                                        <div>
                                            <LinkedInIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='relative overflow-hidden '>
                                <img src={require('../image/team_2-540x740.jpg')} alt="" />
                                <div className='absolute transition-all duration-200 hover:overflow-visible hover:translate-y-[0px] top-[60%] transform translate-y-[45px] bg-[#2B5BAC]  w-full team-content pl-[20px] py-[30px] pr-[16px]'>
                                    <h1 className=' text-[18px] font-bold text-white leading-[1.5]'>Ginger Trap</h1>
                                    <p className=' text-[#6993cd] text-[14px] font-normal mt-[3px]'>Service Analytic</p>
                                    <div className=' flex mt-[15px] text-white gap-3 '>
                                        <div>
                                            <FacebookRoundedIcon />
                                        </div>
                                        <div>
                                            <TwitterIcon />
                                        </div>
                                        <div>
                                            <LinkedInIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='relative overflow-hidden '>
                                <img src={require('../image/team_7-540x740.jpg')} alt="" />
                                <div className='absolute transition-all duration-200 hover:overflow-visible hover:translate-y-[0px] top-[60%] transform translate-y-[45px] bg-[#2B5BAC]  w-full team-content pl-[20px] py-[30px] pr-[16px]'>
                                    <h1 className=' text-[18px] font-bold text-white leading-[1.5]'>Ginger Trap</h1>
                                    <p className=' text-[#6993cd] text-[14px] font-normal mt-[3px]'>Service Analytic</p>
                                    <div className=' flex mt-[15px] text-white gap-3 '>
                                        <div>
                                            <FacebookRoundedIcon />
                                        </div>
                                        <div>
                                            <TwitterIcon />
                                        </div>
                                        <div>
                                            <LinkedInIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='relative overflow-hidden '>
                                <img src={require('../image/team_8-540x740.jpg')} alt="" />
                                <div className='absolute transition-all duration-200 hover:overflow-visible hover:translate-y-[0px] top-[60%] transform translate-y-[45px] bg-[#2B5BAC]  w-full team-content pl-[20px] py-[30px] pr-[16px]'>
                                    <h1 className=' text-[18px] font-bold text-white leading-[1.5]'>Ginger Trap</h1>
                                    <p className=' text-[#6993cd] text-[14px] font-normal mt-[3px]'>Service Analytic</p>
                                    <div className=' flex mt-[15px] text-white gap-3 '>
                                        <div>
                                            <FacebookRoundedIcon />
                                        </div>
                                        <div>
                                            <TwitterIcon />
                                        </div>
                                        <div>
                                            <LinkedInIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            {/*  */}


            {/*  */}
            <div>
                <div>
                    <Container>
                        <div className='flex py-[100px]'>
                            <div className=' w-[45%]'>
                                <p className='text-[#AAAEB3] font-bold tracking-[2px] uppercase text-[12px] mb-[15px]'>Testimonials</p>
                                <h1 className=' text-[45px] font-bold text-[#434A53]'>What our clients <br />
                                    think about us</h1>
                            </div>
                            <div className='relative w-[55%]'>
                                <p className='slider-bg  py-[20px] text-[#696e76] leading-[27.2px] tracking-[0.85px]'>Tigerperch; sea raven lenok codlet algae eater priapumfish sergeant major, mudsucker arrowtooth eeltope, blackchin, river loach murray cod, regal whiptail catfish. Sole nurse shark Red salmon skilfish, threadtail mullet southern flounder, orangespine unicorn fish flounder bobtail snipe eel..</p>

                                <p className=' py-[20px] text-[#696e76] leading-[27.2px] tracking-[0.85px]'>Owens pupfish butterflyfish noodlefish Atlantic eel. Temperate ocean-bass, hussar Steve fish combtail gourami arrowtooth eel, blue whiting horsefish, shark saber-toothed blenny lungfish yellow bass</p>

                                <div className=' mt-[22px]'>
                                    <h1 className=' text-[18px] leading-[1.6] tracking-[-.01em] font-bold text-[#434A53]'>Ben Stiller</h1>
                                    <span className='leading-[1.6] text-[#777F7F]'>Manager of Westwood</span>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            {/*  */}

            {/*  */}
            <div>
                <Company />
            </div>
            {/*  */}

            {/*  */}
            <div>
                <Main_footer />
            </div>
            {/*  */}
        </>
    )
}

export default About
