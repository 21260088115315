import React from 'react'
import Navbar from '../Componets/Navbar'
import Main_footer from '../Componets/Main_footer'
import { Container } from '@mui/material'
import service1 from '../image/icon_new_1.png'
import service2 from '../image/icon_new_2.png'
import service3 from '../image/icon_new_3.png'
import service4 from '../image/icon_new_4.png'
import servi1 from '../image/services_new_1.jpg'
import servi2 from '../image/services_new_2.jpg'
import servi3 from '../image/services_new_3.jpg'
import servi4 from '../image/services_new_4.jpg'
import Company from '../Componets/Company'
import TitleWithStroke from '../Componets/TitleWithStroke'

const Service = () => {
    return (
        <>

            <div className='navbar bg-none p-0'>
                <Navbar />
            </div>

            <div className='py-[100px] service_bg'>
                <Container>
                    <div className='pt-[200px]'>
                        <TitleWithStroke backText="Transx" title="Services" align='left' color="white" />
                    </div>
                </Container>
            </div>

            <div className=' py-[150px]'>
                <Container>
                    <div>
                        <p className=' text-[12px] font-semibold tracking-[1px] uppercase text-[#a9aeb3] mb-[13px]'>Services</p>
                        <h1 className='text-[45px] font-bold text-[#434A53]'>Transportation </h1>
                        <h6 className='text-[45px] mb-[50px] font-bold text-[#434A53]'>Services</h6>
                    </div>
                    <div className=' flex'>
                        <div>
                            <img src={service1} alt="" className=' mb-[30px]' />
                            <h2 className=' text-[18px] font-bold text-[#434A53] mb-[10px]'>Road Freight</h2>
                            <p className=' text-[#696e76]'>Kokanee sprat shrimpfish. Pacific hake false trevally queen parrotfish Black prickleback</p>
                        </div>
                        <div>
                            <img src={service2} alt="" className=' mb-[30px]' />
                            <h2 className=' text-[18px] font-bold text-[#434A53] mb-[10px]'>Shipping Freight</h2>
                            <p className=' text-[#696e76]'>Greenling sleeper; Owens pupfish large-eye bream kokanee sprat shrimpfish. Pacific hake false</p>
                        </div>
                        <div>
                            <img src={service3} alt="" className=' mb-[30px]' />
                            <h2 className=' text-[18px] font-bold text-[#434A53] mb-[10px]'>Air Freight</h2>
                            <p className=' text-[#696e76]'>Mosshead warbonnet sweeper! Greenling sleeper; Owens pupfish large-eye bream kokanee sprat</p>
                        </div>
                        <div>
                            <img src={service4} alt="" className=' mb-[30px]' />
                            <h2 className=' text-[18px] font-bold text-[#434A53] mb-[10px]'>Train Freight</h2>
                            <p className=' text-[#696e76]'>Pacific hake false trevally queen parrotfish Black prickleback humuhumunukunukuapua'a</p>
                        </div>
                    </div>
                </Container>
            </div>

            <div className='bg-[#F3F6F5]'>
                <div className=' pt-[100px]'>
                    <Container>
                        <div className='pb-[50px]'>
                            <p className=' uppercase text-[12px] text-center font-bold text-[#a9aeb3] tracking-[2px]'>Services</p>
                            <h1 className=' text-[45px] text-center font-bold text-[#434A53] leading-[50px] pt-[15px]'>We Manage Lead Logistics
                                <br /> for Leadership</h1>
                        </div>
                        <div className=' flex  rounded' >
                            <div className=' m-2 relative'>
                                <div className=' absolute top-[35%] left-[-20%]'>
                                    <p className='Transporterium'>Transporterium Services</p>
                                </div>
                                <img src={servi1} alt="" className=' top-[-20%]' />
                                <div className=' absolute bottom-0 left-0'>
                                    <div className='flex  justify-between pb-[16px] pl-[25px] items-baseline'>
                                        <h2 className=' text-white font-bold text-[18px]'>Truck Freight</h2>
                                        <h1 className=' pl-[50px] text-[40px] text-[#1eae98] font-bold'>01</h1>
                                    </div>
                                </div>
                            </div>
                            <div className=' m-2 relative'>
                                <div className=' absolute top-[35%] left-[-20%]'>
                                    <p className='Transporterium'>Transporterium Services</p>
                                </div>
                                <img src={servi2} alt="" />
                                <div className=' absolute bottom-0 left-0'>
                                    <div className='flex  justify-between pb-[16px] pl-[25px] items-baseline'>
                                        <h2 className=' text-white font-bold text-[18px]'>Train Freight</h2>
                                        <h1 className=' pl-[50px] text-[40px] text-[#1eae98] font-bold'>02</h1>
                                    </div>
                                </div>
                            </div>
                            <div className=' m-2 relative'>
                                <div className=' absolute top-[35%] left-[-20%]'>
                                    <p className='Transporterium'>Transporterium Services</p>
                                </div>
                                <img src={servi3} alt="" />
                                <div className=' absolute bottom-0 left-0'>
                                    <div className='flex  justify-between pb-[16px] pl-[25px] items-baseline'>
                                        <h2 className=' text-white font-bold text-[18px]'>Plane Freight</h2>
                                        <h1 className=' pl-[50px] text-[40px] text-[#1eae98] font-bold'>03</h1>
                                    </div>
                                </div>
                            </div>
                            <div className=' m-2 relative'>
                                <div className=' absolute top-[35%] left-[-20%]'>
                                    <p className='Transporterium'>Transporterium Services</p>
                                </div>
                                <img src={servi4} alt="" />
                                <div className=' absolute bottom-0 left-0'>
                                    <div className='flex  justify-between pb-[16px] pl-[25px] items-baseline'>
                                        <h2 className=' text-white font-bold text-[18px]'>Ship Freight</h2>
                                        <h1 className=' pl-[50px] text-[40px] text-[#1eae98] font-bold'>04</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>


            <div className='bg-[#32373F] '>
                <div className='testi-bg py-[150px]'>
                    <Container>
                        <p className=' font-bold text-white tracking-[1px] uppercase text-[12px]'>Steps For Work</p>
                        <h1 className=' text-[45px] font-bold text-white'>How We Works</h1>
                        <div className=' flex gap-2'>
                            <div>
                                <h1 className=' text-[#4EAE98] font-bold text-[100px]'>01</h1>
                                <h2 className=' text-white mt-[20px] font-bold pb-[10px]'>Listening you</h2>
                                <p className=' text-white'>Kokanee sprat shrimpfish. Pacific hake false trevally queen parrotfish Black prickleback</p>
                            </div>
                            <div>
                                <h1 className=' text-[#4EAE98] font-bold text-[100px]'>02</h1>
                                <h2 className=' text-white mt-[20px] font-bold pb-[10px]'>Lane pairing analysis</h2>
                                <p className=' text-white'>Greenling sleeper; Owens pupfish large-eye bream kokanee sprat shrimpfish. Pacific hake false</p>
                            </div>
                            <div>
                                <h1 className=' text-[#4EAE98] font-bold text-[100px]'>03</h1>
                                <h2 className=' text-white mt-[20px] font-bold pb-[10px]'>Check all details</h2>
                                <p className=' text-white'>Mosshead warbonnet sweeper! Greenling sleeper; Owens pupfish large-eye bream kokanee sprat</p>
                            </div>
                            <div>
                                <h1 className=' text-[#4EAE98] font-bold text-[100px]'>04</h1>
                                <h2 className=' text-white mt-[20px] font-bold pb-[10px]'>Fast & efficient delivery</h2>
                                <p className=' text-white'>Pacific hake false trevally queen parrotfish Black prickleback humuhumunukunukuapua'a</p>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>

            <div className=' bg-[#F3F6F5] py-[150px]'>
                <div>
                    <Container>
                        <div>
                            <p className=' text-center font-bold tracking-[1px] uppercase text-[#AAAEB3] text-[12px] pb-[10px]'>Pricing</p>
                            <h1 className=' text-center font-bold text-[45px]  leading-[50px] mb-[2px] text-[#434A53]'>The right price for you,<br /><span className='text-[#4EAE98]'>whoever</span>  you are</h1>
                        </div>
                        <div className='pack-part flex pt-[50px] w-[100%] gap-[25px]'>
                            <div className='bg-white price-part-main pt-[32px] px-[30px] pb-[40px]  w-[25%]'>
                                <h1 className=' font-bold pb-[15px] text-[20px]'>One Pack</h1>
                                <div className=' flex py-[20px] text-[#434A53]'>
                                    <h2 className=' text-[50px] leading-[1px] font-bold'>95 </h2>
                                    <h6 className=' text-[30px] leading-[1px] font-semibold ml-[5px]'>USD</h6>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>1 warehouse</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Custom business rules</h1>
                                </div>
                                <div className='opacity-[0.3] flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Real-time rate</h1>
                                </div>
                                <div className='opacity-[0.3] flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>100 freight shipments</h1>
                                </div>
                                <div className=''>
                                    <button className='uppercase py-[14px] px-[40px] mt-[40px] leading-[24px]  rounded-br-2xl border-2 hover:bg-[#4EAE98] hover:text-white
                                    font-semibold text-sm bg-transparent border-[#4EAE98] transition-all text-black '>get startred</button>
                                </div>
                            </div>
                            <div className='bg-white price-part-main pt-[32px] px-[30px] pb-[40px] w-[25%]'>
                                <h1 className=' font-bold  pb-[15px] text-[20px]'>Starter Pack</h1>
                                <div className=' flex py-[20px] text-[#434A53]'>
                                    <h2 className=' text-[50px] leading-[1px] font-bold'>100 </h2>
                                    <h6 className=' text-[30px] leading-[1px] font-semibold ml-[5px]'>USD</h6>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>1 warehouse</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Custom business rules</h1>
                                </div>
                                <div className='opacity-[0.3] flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Real-time rate</h1>
                                </div>
                                <div className='opacity-[0.3] flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>100 freight shipments</h1>
                                </div>
                                <div className=''>
                                    <button className='uppercase py-[14px] px-[40px] mt-[40px] leading-[24px]  rounded-br-2xl border-2 hover:bg-[#4EAE98] hover:text-white
                                    font-semibold text-sm bg-transparent border-[#4EAE98] transition-all text-black '>get startred</button>
                                </div>
                            </div>
                            <div className='bg-white price-part-main pt-[32px] px-[30px] pb-[40px] w-[25%]'>
                                <h1 className=' font-bold pb-[15px] text-[20px]'>Regular Pack</h1>
                                <div className=' flex py-[20px] text-[#434A53]'>
                                    <h2 className=' text-[50px] leading-[1px] font-bold'>150 </h2>
                                    <h6 className=' text-[30px] leading-[1px] font-semibold ml-[5px]'>USD</h6>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>1 warehouse</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Custom business rules</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Real-time rate</h1>
                                </div>
                                <div className='opacity-[0.3] flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>100 freight shipments</h1>
                                </div>
                                <div className=''>
                                    <button className='uppercase py-[14px] px-[40px] mt-[40px] leading-[24px]  rounded-br-2xl border-2 hover:bg-[#4EAE98] hover:text-white
                                     font-semibold text-sm bg-transparent border-[#4EAE98] transition-all text-black '>get startred</button>
                                </div>
                            </div>
                            <div className='bg-[#2B5BAC] price-part-main bg-back pt-[32px] px-[30px] pb-[40px] text-white w-[25%]'>
                                <h1 className=' font-bold  pb-[15px] text-[20px]'>Premium Pack</h1>
                                <div className=' flex py-[20px] text-[#434A53]'>
                                    <h2 className=' text-[50px] leading-[1px] font-bold text-white'>255 </h2>
                                    <h6 className=' text-[30px] leading-[1px] text-white font-semibold ml-[5px]'>USD</h6>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>1 warehouse</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Custom business rules</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>Real-time rate</h1>
                                </div>
                                <div className=' flex items-baseline true-border pt-[20px]'>
                                    <h1 className=' font-normal items-baseline'>100 freight shipments</h1>
                                </div>
                                <div className=''>
                                    <button className='uppercase py-[14px] px-[40px] mt-[40px] leading-[24px]  rounded-br-2xl border-2 hover:bg-white hover:text-[#015BAC] font-semibold text-sm bg-transparent border-[#fff] transition-all text-black '>get startred</button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>

            <div>
                <div>
                    <Container>
                        <div className='flex py-[100px]'>
                            <div className=' w-[45%]'>
                                <p className='text-[#AAAEB3] font-bold tracking-[2px] uppercase text-[12px] mb-[15px]'>Testimonials</p>
                                <h1 className=' text-[45px] font-bold text-[#434A53]'>What our clients <br />
                                    think about us</h1>
                            </div>
                            <div className='relative w-[55%]'>
                                <p className='slider-bg  py-[20px] text-[#696e76] leading-[27.2px] tracking-[0.85px]'>“Rockling Devario deep sea bonefish cutthroat trout streamer fish kaluga sailback scorpionfish sand dab, turkeyfish golden loach sand diver. Leopard danio píntano bonnetmouth; blue whiting, suckermouth armored catfish luderick blackchin kingfish.</p>

                                <p className=' py-[20px] text-[#696e76] leading-[27.2px] tracking-[0.85px]'>Midshipman, lightfish longfin smelt pickerel houndshark whiptail. Barracuda archerfish slimehead broadband dogfish, Pacific hake false trevally queen parrotfish Black prickleback blenny, bigeye squaretail nurseryfish yellowtail barracuda. Halibut: Blacksmelt”</p>

                                <div className=' mt-[22px]'>
                                    <h1 className=' text-[18px] leading-[1.6] tracking-[-.01em] font-bold text-[#434A53]'>Well Singer</h1>
                                    <span className='leading-[1.6] text-[#777F7F]'>Manager of Westwood</span>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>

            <div>
                <Company />
            </div>

            <Main_footer />
        </>
    )
}

export default Service
