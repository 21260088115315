import React from 'react'
import Home from '../Componets/Home'
import Main_footer from '../Componets/Main_footer'
import Navbar from '../Componets/Navbar'
import { Container } from '@mui/material'
import TitleWithStroke from '../Componets/TitleWithStroke'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contact = () => {
    return (
        <>
            <div className='navbar bg-none p-0'>
                <Navbar />
            </div>
            <div>
                <div className=' py-[100px] bg-img-contact'>
                    <Container>
                        <div className='pt-[200px]'>
                            <TitleWithStroke backText="Transx" title="Contact Us" align='left' color="white" />
                        </div>
                    </Container>
                </div>
                <div className='map-bg py-[100px]'>
                    <Container>
                        <div className=' xl:flex md:flex w-[100%]'>
                            <div className='w-[50%]' >
                                <p className='font-semibold text-[#AAAEB3] uppercase text-[12px] tracking-[1px]'>Contact us</p>
                                <h1 className=' font-bold text-[45px] text-[#434A53]'>Contact Info</h1>
                                <div>
                                </div>
                                <div>
                                    <div className='flex gap-6 pt-[20px]'>
                                        <div className='text-[#2F5EAE] border rounded-full text-center text-[18px] leading-[55px] w-[60px] h-[60px] '>
                                            <LocalPhoneIcon />
                                        </div>
                                        <div>
                                            <p className='text-[12px] text-[#778183]'>PHONE</p>
                                            <span>+971 50 582 0577</span>
                                        </div>
                                    </div>
                                    <div className='flex gap-6 pt-[20px]'>
                                        <div className='text-[#2F5EAE] border rounded-full text-center text-[18px] leading-[55px] w-[60px] h-[60px] '>
                                            <MailOutlineIcon />
                                        </div>
                                        <div>
                                            <p className='text-[12px] text-[#778183]'>EMAIL</p>
                                            <span>admin@vrisefreight.com</span>
                                        </div>
                                    </div>
                                    <div className='flex gap-6 pt-[20px]'>
                                        <div className='text-[#2F5EAE] border rounded-full text-center text-[18px] leading-[55px] w-[60px] h-[60px] '>
                                            <RoomOutlinedIcon />
                                        </div>
                                        <div>
                                            <p className='text-[12px] text-[#778183]'>Location</p>
                                            {/* <span>2307 Brooklyn, New York,</span> */}
                                            <p>Warehouse No E17, <br />
                                                Dubai Airport Free Zone, <br />
                                                Dubai</p>
                                        </div>
                                    </div>
                                    <div className='flex gap-6 pt-[20px]'>
                                        <div className='text-[#2F5EAE] border rounded-full text-center text-[18px] leading-[55px] w-[60px] h-[60px] '>
                                            <ShareOutlinedIcon />
                                        </div>
                                        <div>
                                            <p className='text-[12px] text-[#778183]'>FOLLOW US</p>
                                            <div className='flex gap-3  text-[#434A53]'>
                                                <FacebookOutlinedIcon />
                                                <TwitterIcon />
                                                <LinkedInIcon />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='  w-[50%]  pt-[50px]'>
                                <h6 className='font-bold text-[20px] text-[#434A53] pb-[20px]'>Send a Message</h6>
                                <div className=''>
                                    <input type="text" placeholder='First Name' className='border border-[#d4d3d3] rounded outline-none h-[50px] w-full px-5' />
                                </div>
                                <input type="text" placeholder='Email' className='border border-[#d4d3d3] rounded outline-none h-[50px] w-full mt-[20px]  px-5' />
                                <textarea placeholder='Message' cols={40} rows={5} className='w-full mt-[20px] px-5 py-4 outline-none border  rounded border-[#d4d3d3]' />
                                <div>
                                    <button className='uppercase py-[14px] px-[30px] mt-[30px] leading-[24px]  rounded-br-2xl border-2 hover:bg-[#4EAE98] bg-transparent border-[#4EAE98] hover:text-white transition-all text-[14px] text-[#434A53] font-medium'>Send Message</button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div >
                <div className='pt-[50px]'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d28862.94080438823!2d55.36404266570118!3d25.27503892786197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWarehouse%20No%20E17%2C%20Dubai%20Airport%20Free%20Zone%2C%20%20Dubai!5e0!3m2!1sen!2sin!4v1727678838889!5m2!1sen!2sin" width={'100%'} height={'500px'} referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div >
            <div>
                <Main_footer />
            </div>
        </>
    )
}

export default Contact
