import { Container } from '@mui/material'
import React from 'react'
import StepsCard from '../Componets/StepsCard'

// Import Swiper React components

// Import Swiper styles
import "swiper/css";

// import required modules
import {
    Autoplay,
    Scrollbar,
} from "swiper/modules";
// import TitleWithStroke from '../Componets/TitleWithStroke';
import { Swiper, SwiperSlide } from 'swiper/react';

const Steps = () => {
    return (
        <>
            <div className='bg-[#F2F6F5] py-[100px]'>
                <Container>
                    {/* <TitleWithStroke category="How We Work" title="Steps of Cooperation" align='left' offset="10"/> */}

                    <div>
                        <p className='font-bold tracking-[1.5px] text-[#a9aeb3] uppercase text-[12px]'>History</p>
                        <h1 className=' text-[#434A53] text-[45px] font-bold'>Our company long History</h1>
                    </div>
                    <br />
                    <br />
                    <div className="step_grid">
                        <div className="space"></div>

                        <div className="steps_slider" >
                            <Swiper
                                autoplay={{
                                    delay: 1500,
                                    disableOnInteraction: false,
                              
                                }}

                                scrollbar={{ draggable: true }}
                                modules={[
                                    Autoplay,
                                    Scrollbar,
                                ]}
                                slidesPerView={1}
                                breakpoints={{
                                    320: {
                                        width: 320,
                                        slidesPerView: 1,
                                    },
                                    640: {
                                        width: 640,
                                        slidesPerView: 2,
                                    },
                                    992: {
                                        width: 992,
                                        slidesPerView: 3,
                                    }
                                }}
                            >
                                <SwiperSlide>
                                    <StepsCard
                                        day="1993"
                                        month="year"
                                        title="Brook trout powen harelip sucker"
                                        desc="Temperate ocean-bass, hussar Steve fish combtail gourami arrowtooth eel, blue whiting, Indian mul soapfish. Slickhead smelt catfish."
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StepsCard
                                        day="1997"
                                        month="year"
                                        title="Rice eel jawfish roach longjaw"
                                        desc="Golden trout giant gourami Celebes rainbowfish straptail electric catfish zebrafish batfish starry flounder North American darter"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StepsCard
                                        day="2002"
                                        month="year"
                                        title="Longjaw mudsucker sawtooth eel"
                                        desc="Pacific hake false trevally queen parrotfish Black pricklebackmosshead warbonnet sweeper! Greenling large-eye "
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <StepsCard
                                        day="2004"
                                        month="year"
                                        title="Ronquil coho salmon red snapper"
                                        desc="Australian lungfish southern Dolly Varden? Black angelfish discus zebrafish thornyhead Antarctic cod; clownfish"
                                    />
                                </SwiperSlide>
                                <SwiperSlide>
                                    <StepsCard
                                        day="2005"
                                        month="year"
                                        title="Brook trout powen harelip sucker"
                                        desc="Temperate ocean-bass, hussar Steve fish combtail gourami arrowtooth eel, blue whiting, Indian mul soapfish. Slickhead smelt catfish"
                                    />
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </Container>






            </div>
        </>
    )
}

export default Steps
